import React, { useState } from "react";
import Header from "../../commons/header/header";
import Footer from "../../commons/footer/footer";
import "./gallery.css"; // Ensure you have a CSS file for styling
import image1 from "../../../assests/images/aids-bg.jpeg";
import image2 from "../../../assests/images/bg-cse.jpeg";
import image3 from "../../../assests/images/agri-bg.jpeg";
import bg from "../../../assests/images/agri-bg.jpg";
import image4 from "../../../assests/images/1.png";
import image5 from "../../../assests/images/2.png";
import image6 from "../../../assests/images/3.png";
import image7 from "../../../assests/images/4.png";
import image8 from "../../../assests/images/5.png";
import image9 from "../../../assests/images/6.png";
import image10 from "../../../assests/images/7.png";
import image12 from "../../../assests/images/9.png";
import image13 from "../../../assests/images/10.png";
import image14 from "../../../assests/images/8.png";
import image15 from "../../../assests/images/12.png";
import image16 from "../../../assests/images/13.png";
import image17 from "../../../assests/images/14.png";
import image18 from "../../../assests/images/15.png";
// import image19 from "../../../assests/images/background-1.jpg";
// import image20 from "../../../assests/images/biomedical-bg.jpg";
import COMP1 from "../../../assests/images/COMP-1.jpeg";
//Newly Added
import N1 from "../../../assests/images/IMAGE-1.jpeg";
import N2 from "../../../assests/images/IMAGE-2.jpeg";
import N3 from "../../../assests/images/IMAGE-3.jpeg";
import N4 from "../../../assests/images/IMAGE-4.jpeg";
import N5 from "../../../assests/images/IMAGE-5.jpeg";
import N6 from "../../../assests/images/IMAGE-6.jpeg";
import N7 from "../../../assests/images/IMAGE-7.jpeg";
import N8 from "../../../assests/images/IMAGE-8.jpeg";
import N9 from "../../../assests/images/IMAGE-9.jpeg";
import N10 from "../../../assests/images/IMAGE-10.jpeg";
import N11 from "../../../assests/images/IMAGE-11.jpeg";
import N12 from "../../../assests/images/IMAGE-12.jpeg";



const images = [
  { src: image1, alt: "Image 1", category: "Events" },
  { src: image2, alt: "Image 2", category: "Events" },
  { src: image3, alt: "Image 3", category: "Events" },
  { src: image4, alt: "Image 4", category: "Events" },
  { src: image5, alt: "Image 5", category: "Sports" },
  { src: image6, alt: "Image 6", category: "Sports" },
  { src: image7, alt: "Image 7", category: "Events" },
  { src: image8, alt: "Image 8", category: "Events" },
  { src: image9, alt: "Image 9", category: "Events" },
  { src: image10, alt: "Image 10", category: "Events" },
  { src: image12, alt: "Image 12", category: "Events" },
  { src: image13, alt: "Image 13", category: "Events" },
  { src: image14, alt: "Image 14", category: "Events" },
  { src: image15, alt: "Image 15", category: "Events" },
  { src: image16, alt: "Image 16", category: "Events" },
  { src: image17, alt: "Image 17", category: "Events" },
  { src: image18, alt: "Image 18", category: "Events" },
  // { src: image19, alt: "Image 19", category: "Events" },
  // { src: image20, alt: "Image 20", category: "Sports" },
  { src: COMP1, alt: "Image 1", category: "Events" },

  { src: N1, alt: "Image 1", category: "Events" },
  { src: N2, alt: "Image 1", category: "Events" },
  { src: N3, alt: "Image 1", category: "Events" },
  { src: N4, alt: "Image 1", category: "Events" },
  { src: N5, alt: "Image 1", category: "Events" },
  { src: N6, alt: "Image 1", category: "Events" },
  { src: N7, alt: "Image 1", category: "Events" },
  { src: N8, alt: "Image 1", category: "Events" },
  { src: N9, alt: "Image 1", category: "Events" },
  { src: N10, alt: "Image 1", category: "Events" },
  { src: N11, alt: "Image 1", category: "Events" },
  { src: N12, alt: "Image 1", category: "Events" }


  // Add more images with different categories
];

const Gallery = () => {
  const [filter, setFilter] = useState("All");

  const handleFilterChange = (category) => {
    setFilter(category);
  };

  const filteredImages = filter === "All" ? images : images.filter(image => image.category === filter);

  return (
      <>
      <Header />
      <div className="image-section">
        <img src={bg} alt="Gallery" className="department-image" />
        <div className="overlay-text">Gallery</div>
      </div>
      <div className="gallery-menu">
        <button onClick={() => handleFilterChange("All")}>All</button>
        <button onClick={() => handleFilterChange("Events")}>Events</button>
        <button onClick={() => handleFilterChange("Sports")}>Sports</button>
        {/* Add more buttons for additional categories */}
      </div>
      <div className="gallery-images">
        {filteredImages.map((image, index) => (
          <img key={index} src={image.src} alt={image.alt} className="gallery-image" />
        ))}
      </div>
      <Footer />
      </>
  );
};

export default Gallery;
