import React from "react";
import { Container, ListGroup } from "react-bootstrap";
import Header from "../../commons/header/header";
import Footer from "../../commons/footer/footer";
import "./aicte.css"; // Ensure you're importing the correct CSS file

const PdfLinks = () => {
  const pdfLinks = [
    { name: "1. EOA Report 2024-25", url: "/pdf/2024-2025.pdf" },
    { name: "2. EOA Report 2023-24", url: "/pdf/2023-2024.pdf" },
    { name: "3. EOA Report 2022-23", url: "/pdf/2022-2023.pdf" },
    { name: "4. EOA Report 2021-22", url: "/pdf/2021-2022.pdf" },
    { name: "5. EOA Report 2020-21", url: "/pdf/2020-2021.pdf" },
    { name: "6. EOA Report 2019-20", url: "/pdf/2019-2020.pdf" },
    { name: "7. EOA Report 2018-19", url: "/pdf/2018-2019.pdf" },
    { name: "8. EOA Report 2017-18", url: "/pdf/2017-2018.pdf" },
    { name: "9. EOA Report 2016-17", url: "/pdf/2016-2017.pdf" },
    { name: "10. EOA Report 2015-16", url: "/pdf/2015-2016.pdf" },
    { name: "11. EOA Report 2014-15", url: "/pdf/2014-2015.pdf" },
    { name: "12. EOA Report 2013-14", url: "/pdf/2013-2014.pdf" },
  ];

  return (
    <>
      <Header />
      <div className="my-4">
        <Container className="mt-3">
          <ListGroup>
            {pdfLinks.map((pdf, index) => (
              <ListGroup.Item key={index} className="pdf-link-list-group-item">
                <a href={pdf.url} target="_blank" rel="noopener noreferrer">
                  {pdf.name}
                  <i
                    className="fas fa-file-pdf pdf-icon"
                    aria-hidden="true"
                  ></i>
                </a>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PdfLinks;
