import React from "react";
import "./about.css"; // Import CSS for styling
import image3 from "../../../assests/images/about.png";
import COMP1 from "../../../assests/images/COMP-1.jpeg";


const About = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <h2>About Us</h2>
        <p>
          Asian College of Engineering and Technology is promoted by the Krishna
          Charitable Trust, Mettupalayam. The Trust was established in the year
          2011 and is registered with Income Tax Authorities under Section 12 of
          the Income Tax Act.. The Trust was established with an objective of
          establishing, Educational institutions in various fields like
          Engineering, Medical, Paramedical, Nursing, Dental, Electronics,
          Communications, Computer Applications, Technical, Academic, Arts and
          Science, Business and Management, Vocational, Sports and Games,
          Teacher training, and such other Educational fields. The trust members
          have rich experience in the area of academic, industry, business and
          agriculture.
        </p>
        <button className="read-more-btn">Read More</button>
      </div>
      <div className="about-image">
        <img src={image3} alt="About Us" />
      </div>
      <div className="about-news-events">
        <div className="news-events-header">
          <h1>News & Events</h1>
        </div>
        <div className="news-events-content">
          <div className="news-items-wrapper">
            <div className="news-item">
              <h4 className="eventdt">20 NOV 2024</h4>
              <p className="eventdtls">
              The management,Principal and faculty congratulate our student Sushmitha III YEAR  AI & DS has secured III place in the event 5000M Running conducted by KCT for Anna university zone XI sports competition
              </p>
            </div>
            {/* <div className="news-item">
              <h4 className="eventdt">24 May 2024</h4>
              <p className="eventdtls">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Recusandae quod maxime iusto molestias! Aperiam nisi esse
                adipisci odit aspernatur, doloribus, nesciunt, officia sunt a
                accusamus corporis quisquam eum repudiandae suscipit.
              </p>
            </div>
            <div className="news-item">
              <h4 className="eventdt">8 Mar 2024</h4>
              <p className="eventdtls">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Ducimus eos officia illum porro amet aliquam molestias placeat
                dolor saepe doloremque laudantium voluptatem animi impedit sunt,
                commodi, magnam mollitia harum obcaecati!
              </p>
            </div>
            <div className="news-item">
              <h4 className="eventdt">6 JAN 2024</h4>
              <p className="eventdtls">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                eum sapiente ea voluptas delectus veniam vero deserunt aperiam
                totam! Perferendis repellendus vitae esse eos expedita commodi,
                praesentium omnis provident eum.
              </p>
            </div> */}
            <img src={COMP1} alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
